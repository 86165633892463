import { Refine } from "@refinedev/core";
import {
	ErrorComponent,
	notificationProvider,
	ThemedLayoutV2,
	ThemedTitleV2,
} from "@refinedev/antd";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import routerBindings, {
	NavigateToResource,
	UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";

import dataProvider from "@refinedev/simple-rest";


import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import "@refinedev/antd/dist/reset.css";


import { API_URL } from '../../config/settings';
import { ColorModeContextProvider } from "../../contexts/color-mode";
import { AppIcon } from "../app-icon";
import { DealershipsList } from '../dealerships';
import { Header } from "../header";
import { JobsList } from '../jobs';
import { QBUsersList } from '../qbUsers';
import { SFUsersList } from '../sfUsers';
import { QBLogs } from '../qblogs';



function App() {

	const { t, i18n } = useTranslation();

	const i18nProvider = {
		translate: (key: string, params: object) => t(key, params),
		changeLocale: (lang: string) => i18n.changeLanguage(lang),
		getLocale: () => i18n.language,
	};

	return (
		<BrowserRouter>

			<RefineKbarProvider>
				<ColorModeContextProvider>
					<Refine
						dataProvider={dataProvider(API_URL)}
						notificationProvider={notificationProvider}
						i18nProvider={i18nProvider}
						routerProvider={routerBindings}
						resources={[
							{
								name: 'Settings'
							},
							{
								name: 'dealerships',
								list: '/dealerships',
								meta: {
									parent: 'Settings'
								}
							},
							{
								name: 'qbUsers',
								list: '/qbUsers',
								meta: {
									canDelete: true,
									label: 'Quickbooks users',
									parent: 'Settings'
								}
							},
							{
								name: 'sfUsers',
								list: '/sfUsers',
								meta: {
									canDelete: true,
									label: 'Salesforce users',
									parent: 'Settings'
								}
							},
							{
								name: "jobs",
								list: "/jobs",
								show: "/jobs/show/:id",
								meta: {
									canDelete: false,
								},
							},
							{
								name: "logs",
								list: '/qbLogs',
								meta: {
									label: 'QB Logs'
								}
							}
						]}
						options={{
							syncWithLocation: true,
							warnWhenUnsavedChanges: true,
						}}
						>
							<Routes>
								<Route
									element={
										<ThemedLayoutV2
											Header={Header}
											Title={({ collapsed }) => (
												<ThemedTitleV2
													collapsed={collapsed}
													text="Sundek"
													icon={<AppIcon />}
												/>
											)}
										>
										<Outlet />
										</ThemedLayoutV2>
									}
								>

									<Route path="/dealerships">
										<Route index element={ <DealershipsList /> } />
									</Route>

									<Route path="/qbUsers">
										<Route index element={ <QBUsersList /> } />
									</Route>

									<Route path="/sfUsers">
										<Route index element={ <SFUsersList /> } />
									</Route>

									<Route
										index
										element={<NavigateToResource resource="jobs" />}
									/>
										<Route path="/jobs">
										<Route index element={<JobsList />} />
									</Route>

									<Route path="/qbLogs">
										<Route index element={ <QBLogs /> } />
									</Route>

								</Route>

							</Routes>

						<RefineKbar />
						<UnsavedChangesNotifier />
					</Refine>
				</ColorModeContextProvider>
			</RefineKbarProvider>
		</BrowserRouter>
	);

}

export default App;