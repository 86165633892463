import { BooleanField, DeleteButton, EditButton, List, useModalForm, useTable } from "@refinedev/antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Switch, Table } from "antd";
import React from 'react';


export const DealershipsList = () => {

    const [ currentDealershipId, setCurrentDealershipId ] = React.useState(null);

	const { tableProps, tableQueryResult } = useTable({
		resource: 'dealerships'
	});


    const {
        modalProps: addHAModalProps,
        formProps: addHAFormProps,
        show: addHAModalShow,
    } = useModalForm({
        action: "create",
        onMutationSuccess: (data, variables, context, isAutoSave) => {
            tableQueryResult.refetch();
        },
        resource: "dealerships/addHA"
    });


	const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm({
        action: "create",
        resource: "dealerships"
    });


    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm({
        action: "edit",
        resource: "dealerships",
        warnWhenUnsavedChanges: true,
    });


    const TrueIcon = () => <span>✅</span>;

    const FalseIcon = () => <span>❌</span>;


    const displayHADealershipAdd = (dealership_id) => {

        addHAFormProps.form.setFieldValue('dealership_id', dealership_id);
        setCurrentDealershipId(dealership_id);
        addHAModalShow();

    }


	return (

		<>

			<List
				createButtonProps={{
                    onClick: () => {
                        createModalShow();
                    }
                }}
			>

				<Table { ...tableProps } rowKey="id">

					<Table.Column dataIndex="id" title="ID" />
					<Table.Column dataIndex="sf_dealership" title="Salesforce dealership" />
	                <Table.Column dataIndex="qb_file" title="Quickbooks file path" />
                    <Table.Column dataIndex="template_name" title="Quickbooks invoice template name" />
                    <Table.Column align="center" dataIndex="house_accounts_enabled" title="House accounts enabled" render={ (value) => (
                        <BooleanField
                            value={value}
                            trueIcon={<TrueIcon />}
                            falseIcon={<FalseIcon />}
                            valueLabelTrue="Enabled"
                            valueLabelFalse="Disabled"
                        />
                        ) } 
                    />

	                <Table.Column
	                    title="Actions"
	                    dataIndex="actions"

	                    render={ (_text, record) => (

	                        <Space>

	                            <EditButton
                                    size="small"
                                    recordItemId={ record.id }
                                    hideText
                                    onClick={ () => editModalShow(record.id) }
                                />

                                <DeleteButton 
                                	hideText
                                	recordItemId={ record.id }
                            	/>

	                        </Space>

	                    )}
	                />

				</Table>

			</List>


            <Modal { ...addHAModalProps }>

                <Form { ...addHAFormProps } layout="vertical">

                    <Form.Item 
                        hidden
                        name="dealership_id"
                        value={ currentDealershipId }
                    >
                        <Input value={ currentDealershipId } />
                    </Form.Item>

                    <Form.Item 
                        label="Dealership HA as appears in Salesforce"
                        name="house_account"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>


			<Modal { ...createModalProps }>

                <Form { ...createFormProps }layout="vertical">

                    <Form.Item 
                        label="Dealership as appears in Salesforce"
                        name="sf_dealership"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Quickbooks file path from server."
                        name="qb_file"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Quickbooks invoice template name."
                        name="template_name"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="House accounts enabled"
                        name="house_accounts_enabled"
                        rules={ [ { required: false } ] }
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                </Form>

            </Modal>


            <Modal { ...editModalProps }>

                <Form { ...editFormProps }layout="vertical">

                    <Form.Item 
                        label="Dealership as appears in Salesforce"
                        name="sf_dealership"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Quickbooks file path from server."
                        name="qb_file"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item 
                        label="Quickbooks invoice template name."
                        name="template_name"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="House accounts enabled"
                        name="house_accounts_enabled"
                        rules={ [ { required: false } ] }
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                </Form>

            </Modal>

		</>

	);

}