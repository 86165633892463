import { List, useTable } from "@refinedev/antd";
import { Table } from "antd";
import React from 'react';


const to2 = (num) => num < 10 ? `0${ num }` : num;


export const QBLogs = () => {

	const { tableProps } = useTable({
		resource: 'jobs/logs'
	});


	return (

		<List>

			<Table { ...tableProps } rowKey="id">

				<Table.Column dataIndex="id" title="ID" />
				<Table.Column dataIndex="opportunity_no" title="Opportunity" />
				<Table.Column dataIndex="createdAt" title="Created at" render={ (_, record) => {

					let rDate = new Date(record.createdAt);

					return `${ rDate.getFullYear() }-${ to2(rDate.getMonth() + 1) }-${ to2(rDate.getDate()) } ${ to2(rDate.getHours()) }:${ to2(rDate.getMinutes()) }:${ to2(rDate.getSeconds()) }`;

				}} />
				<Table.Column dataIndex="updatedAt" title="Updated last time" render={ (_, record) => {

					let rDate = new Date(record.updatedAt);

					return `${ rDate.getFullYear() }-${ to2(rDate.getMonth() + 1) }-${ to2(rDate.getDate()) } ${ to2(rDate.getHours()) }:${ to2(rDate.getMinutes()) }:${ to2(rDate.getSeconds()) }`;

				} } />
				<Table.Column dataIndex="result_status_Id" title="Status" 

					render={ (_, record) => {

					let value;

					switch (record.sf2qbResultStatus_c?.status){

						case 'STORED_JOB':
							value = 'Generated and stored JOB';
							break;

						case 'STORED_INVOICES':
							value = 'Generated and stored JOB and INVOICES';
							break;

						case 'ERROR':
							value = 'Error';
							break;


						default:
							value = '';
					}

					return value;

					} }

				/>
				<Table.Column dataIndex="message" title="Error description" />

			</Table>


		</List>

	)

}