import { DeleteButton, EditButton, List, useModalForm, useTable } from "@refinedev/antd";
import { Form, Input, Modal, Space, Table } from "antd";
import React from 'react';


export const SFUsersList = () => {

	const { tableProps } = useTable({
		resource: 'sfUsers'
	});


	const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm({
        action: "create",
        resource: "sfUsers"
    });


    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm({
        action: "edit",
        resource: "sfUsers",
        warnWhenUnsavedChanges: true,
    });


	return (

		<>

			<List
				createButtonProps={{
                    onClick: () => {
                        createModalShow();
                    }
                }}
			>

				<Table { ...tableProps } rowKey="id">

					<Table.Column dataIndex="id" title="ID" />
                    <Table.Column dataIndex="sfUserFirstName" title="Name (as appear on SF)" render={ (value, record) => `${ record.sfUserLastName }, ${ record.sfUserFirstName }`} />
					<Table.Column dataIndex="qbFullName" title="QB Abbreviation" />
	                <Table.Column dataIndex="sfUserEmail" title="E-mail" />
                    <Table.Column dataIndex="sfUserCorpPosition" title="Sundek corporate position" />
                    <Table.Column dataIndex="sfUserPosition" title="Position" />
                    <Table.Column dataIndex="ccEmail" title="CC E-mails" />

	                <Table.Column
	                    title="Actions"
	                    dataIndex="actions"

	                    render={ (_text, record) => (

	                        <Space>

	                            <EditButton
                                    size="small"
                                    recordItemId={ record.id }
                                    hideText
                                    onClick={ () => editModalShow(record.id) }
                                />

                                <DeleteButton 
                                	hideText
                                	recordItemId={ record.id }
                            	/>

	                        </Space>

	                    )}
	                />

				</Table>

			</List>

			<Modal { ...createModalProps }>

                <Form { ...createFormProps }layout="vertical">

                    <Form.Item 
                        label="First name"
                        name="sfUserFirstName"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Last name."
                        name="sfUserLastName"
                        
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label="QB user abbreviation"
                        name="qbFullName"
                        rules={ [ { required: false } ] }
                    >

                        <Input />

                    </Form.Item>


                    <Form.Item
                        label="Abbreviation for QB"
                        name="sfUserAbbreviation"
                        rules={ [ { required: false } ] }
                    >

                        <Input />

                    </Form.Item>

                    <Form.Item 
                        label="User e-mail"
                        name="sfUserEmail"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Sundek corporate position"
                        name="sfUserCorpPosition"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Position"
                        name="sfUserPosition"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="CC e-mails list"
                        name="ccEmail"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>


            <Modal { ...editModalProps }>

                <Form { ...editFormProps }layout="vertical">

                    <Form.Item 
                        label="First name"
                        name="sfUserFirstName"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Last name."
                        name="sfUserLastName"
                        
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label="QB user abbreviation"
                        name="qbFullName"
                        rules={ [ { required: true } ] }
                    >

                        <Input />

                    </Form.Item>


                    <Form.Item
                        label="Abbreviation 2 (as in users spreadsheet)"
                        name="sfUserAbbreviation"
                        rules={ [ { required: false } ] }
                    >

                        <Input />

                    </Form.Item>

                    <Form.Item 
                        label="User e-mail"
                        name="sfUserEmail"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Sundek corporate position"
                        name="sfUserCorpPosition"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Position"
                        name="sfUserPosition"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="CC e-mails list"
                        name="ccEmail"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>

		</>

	);

}