import { DeleteButton, EditButton, List, useModalForm, useSelect, useTable } from "@refinedev/antd";
import { useMany } from '@refinedev/core';
import { Form, Input, Modal, Select, Space, Table } from "antd";
import React from 'react';


export const QBUsersList = () => {

	const { tableProps } = useTable({
		resource: 'qbUsers'
	});


    const qbUsersIds = tableProps.dataSource?.map((record) => record.dealershipFile_Id?.toString()) || [ ];

    const { data: dealership, areDealershipsLoading } = useMany({
        resource: "dealerships",
        ids: qbUsersIds
    });

    const dealerships = useSelect({
        optionLabel: 'sf_dealership',
        resource: 'dealerships'
    });


	const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModalShow,
    } = useModalForm({
        action: "create",
        resource: "qbUsers"
    });


    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm({
        action: "edit",
        resource: "qbUsers",
        warnWhenUnsavedChanges: true,
    });


	return (

		<>

			<List
				createButtonProps={{
                    onClick: () => {
                        createModalShow();
                    }
                }}
			>

				<Table { ...tableProps } rowKey="id">

					<Table.Column dataIndex="id" title="ID" />
                    <Table.Column dataIndex="username" title="Quickbooks username" />
					<Table.Column dataIndex="name" title="Name" />
	                <Table.Column dataIndex="lastname" title="Last name" />
                    <Table.Column dataIndex="dealershipFile_Id" title="Dealership + QB File" render={ (value) => {
                        if(areDealershipsLoading) return "Loading dealerships + files relations list..."
                        const displayRecord = dealership?.data.find((item) => item.id === value);
                        return displayRecord?.sf_dealership
                    }}/>
                    <Table.Column dataIndex="dealershipFile_Id" title="Quickbooks file path" render={ (value) => {
                        if(areDealershipsLoading) return "Loading dealerships + files relations list..."
                        const displayRecord = dealership?.data.find((item) => item.id === value);
                        return displayRecord?.qb_file
                    }}/>

	                <Table.Column
	                    title="Actions"
	                    dataIndex="actions"

	                    render={ (_text, record) => (

	                        <Space>

	                            <EditButton
                                    size="small"
                                    recordItemId={ record.id }
                                    hideText
                                    onClick={ () => editModalShow(record.id) }
                                />

                                <DeleteButton 
                                	hideText
                                	recordItemId={ record.id }
                            	/>

	                        </Space>

	                    )}
	                />

				</Table>

			</List>

			<Modal { ...createModalProps }>

                <Form { ...createFormProps }layout="vertical">

                    <Form.Item 
                        label="Quickbooks username"
                        name="username"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Quickbooks password."
                        name="password"
                        
                        rules={ [ { required: true } ] }
                    >
                        <Input.Password />
                    </Form.Item>


                    <Form.Item
                        label="Dealership"
                        name="dealershipFile_Id"
                        rules={ [ { required: true } ] }
                        style={{ display: 'inline-block', marginRight: '0.5rem', minWidth: '17rem', maxWidth: '30rem' }}
                    >

                        <Select { ...dealerships?.selectProps } />

                    </Form.Item>

                    <Form.Item 
                        label="User's name"
                        name="name"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="User's lastname"
                        name="lastname"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="User's mobile number"
                        name="mobile_no"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="User's email"
                        name="email"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>


            <Modal { ...editModalProps }>

                <Form { ...editFormProps } layout="vertical">

                    <Form.Item 
                        label="Quickbooks username"
                        name="username"
                        rules={ [ { required: true } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Quickbooks password (only if you need to update user's password)."
                        name="password"
                        
                        rules={ [ { required: false } ] }
                    >
                        <Input.Password />
                    </Form.Item>


                    <Form.Item
                        label="Dealership"
                        name="dealershipFile_Id"
                        rules={ [ { required: true } ] }
                        style={{ display: 'inline-block', marginRight: '0.5rem', minWidth: '17rem', maxWidth: '30rem' }}
                    >

                        <Select { ...dealerships?.selectProps } />

                    </Form.Item>

                    <Form.Item 
                        label="User's name"
                        name="name"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="User's lastname"
                        name="lastname"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="User's mobile number"
                        name="mobile_no"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="User's email"
                        name="email"
                        rules={ [ { required: false } ] }
                    >
                        <Input />
                    </Form.Item>

                </Form>

            </Modal>

		</>

	);

}